//Bootstrap Customization
$border-color: #DFDFDF;
$height: 30px;
.table-responsive {
    max-height: 700px;
    overflow-y: auto;
}

.bootstrap-table {
    * {
        border: none !important;
    }
}

table,
.table {
    border: solid 0.1px $border-color !important;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    thead {
        border-top: 2px solid #F4F4F4;
        tr {
            background-color: #FFFFFF !important;
        }
    }
    tbody {
        tr {
            td,
            th {
                background-color: inherit !important;
            }
            &,
            & td,
            & th {
                background-color: #FFFFFF !important;
            }
            &:nth-child(odd) {
                &,
                & td,
                & th,
                & .sticky-col,
                & .sticky-col1 {
                    background-color: #F9F9F9 !important;
                }
            }
            &:nth-child(even) {
                &,
                & td,
                & th,
                & .sticky-col,
                & .sticky-col1 {
                    background-color: #FFFFFF !important;
                }
            }
        }
    }
    th {
        background-color: #FFFFFF;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.54);
        background-image: none;
        text-align: left;
        padding-left: 5px;
        border: solid 1px $border-color !important;
    }
    tr {
        height: $height;
        border: 0;
        position: relative;
        th,
        td {
            line-height: $height !important;
            padding: 0px 10px !important;
            vertical-align: middle !important;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    td {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
        border: solid 1px $border-color !important;
        position: relative;
        overflow: hidden;
        color: rgba(0, 0, 0, .87);
        div.edit {
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: #039BE5;
            right: -10px;
            top: -10px;
            transform: rotate(45deg);
        }
        input[type="text"] {
            height: 25px !important;
            margin: 5px 10px;
            width: auto !important;
            min-width: auto !important;
        }
    }
    td.error-section {
        background-color: rgba(3, 155, 229, 0.4);
        color: #000000;
    }
    td.action,
    th.action {
        font-size: 12px;
        button {
            background-color: transparent;
            border: none;
            height: 15px;
            width: 15px;
            margin: 5px;
            text-align: center;
        }
        .edit-button {
            color: #039BE5;
        }
        .view-details-button {
            color: #787878;
        }
        input {
            height: 15px;
            width: 15px;
            margin: 5px;
            vertical-align: initial;
        }
    }
    .expand-button,
    .collapse-button {
        background-color: transparent;
        border: none;
        height: 20px;
        width: 20px;
        margin: 5px;
        text-align: center;
        color: #039BE5;
        vertical-align: top;
        margin-right: 10px;
    }
    .collapse-button {
        transform: rotate(90deg);
        transform-origin: 100%;
        margin-top: 10px;
    }
    .sticky-col {
        position: sticky;
        position: -webkit-sticky;
        z-index: 1;
        top: 1;
        border: solid 1px $border-color !important;
    }
    .sticky-col1 {
        position: sticky;
        position: -webkit-sticky;
        z-index: 3;
        top: 1;
        border: solid 1px $border-color !important;
    }
    td:not(.sticky-col):not(.sticky-col1) {
        background-color: inherit !important;
    }
    .first-col {
        width: 100px;
        min-width: 120px;
        max-width: 120px;
        left: 0px;
    }
    .second-col {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
        left: 120px;
    }
    .action-row {
        position: sticky !important;
        left: 0;
        border-right: none !important;
    }
}

.table-responsive {
    .table {
        tbody {
            tr {
                &:nth-child(odd) {
                    &,
                    &>td,
                    &>th {
                        background-color: #F9F9F9 !important;
                    }
                }
                &:nth-child(even) {
                    &,
                    &>td,
                    &>th {
                        background-color: #FFFFFF !important;
                    }
                }
            }
        }
    }
}

// Additional specific styling for striped rows
.table-responsive {
    .table {
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: #FFFFFF !important;
                }
                &:nth-child(odd) {
                    background-color: #F9F9F9 !important;
                }
            }
        }
    }
}

tbody>tr:hover {
    background-color: #E7F1EC !important;
}

.table>tbody>tr>td.in-space-none {
    padding: 0;
}

thead {
    th {
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) 50%;
        .both {
            background-image: none !important;
        }
        &:first-child .th-inner {
            padding-left: 35px;
        }
    }
    th.asc {
        background-image: url('../assets/images/Sort_accending.png') !important;
        padding-right: 30px !important;
    }
    th.desc {
        background-image: url('../assets/images/Sort_decending.png') !important;
        padding-right: 30px !important;
    }
}

.bootstrap-table .table:not(.table-condensed)>tbody>tr>td {
    &:first-child {
        padding-left: 35px;
    }
    &:last-child {
        padding-right: 0px;
    }
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td {
    border: 0;
}

.table-bordered {
    border: 0;
}

.bootstrap-table .table {
    border: 0;
    >thead>tr>th {
        border: 0;
    }
}

$borderless-row-height: 30px;
.borderless-table {
    border: none !important;
    width: auto !important;
    * {
        border: none !important;
        background-color: transparent !important;
        padding-left: 0px !important;
    }
    *:hover {
        background-color: transparent !important;
    }
    tr {
        height: $borderless-row-height !important;
        td,
        th {
            line-height: $borderless-row-height !important;
            width: auto !important;
        }
    }
}