@use './variables.scss' as *;
button {
    cursor: pointer !important;
    outline: none !important;
}

.clear-button {
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    padding: 0px;
    &.primary {
        color: $app_color;
    }
}

.clear-button.primary {
    color: $app_color;
}

button.primary-btn {
    border: none;
    outline: none;
    background-color: $app_color;
    color: #FFFFFF;
    padding: 10px 20px;
}

button.secondary-btn {
    border: none;
    outline: none;
    background-color: #737373;
    color: #FFFFFF;
    padding: 10px 20px;
}

button.ternary-btn {
    border: none;
    outline: none;
    background-color: #999999;
    color: #FFFFFF;
    padding: 10px 20px;
}

button.danger-btn {
    border: none;
    outline: none;
    background-color: #D9534F;
    color: #FFFFFF;
    padding: 10px 20px;
}

button.sub-action {
    height: 40px;
    vertical-align: bottom;
    float: right;
}

.action-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: medium none;
    background-color: transparent;
    vertical-align: middle;
    outline: 0;
    // border: 0;
    cursor: pointer;
}

.add-button {
    width: 50px;
    height: 50px;
    color: #FFFFFF;
    font-size: 40px;
}

.fab {
    position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;
    border: none;
    outline: none;
    box-shadow: -3px 3px 15px #888888;
    font-size: 36px;
}

.fab.secondary {
    background-color: #FFFFFF;
}

.fab.right-bottom {
    right: 10px;
    bottom: 10px;
}

.remove-button {
    color: #DC3545;
    vertical-align: middle;
}

.add-filter-button {
    color: #28A745;
    vertical-align: middle;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}