// Colors
@use "sass:color";
$app_color: #65AC1E;
$cyan: #65AC1E;
$yellow: #FFEB3B;
$grey: #9E9E9E;
// Variables
$num-of-tabs: 4;
$tab-header-height: 56px;
$tab-content-padding: 20px;
// Utility Classes
.margin-vert-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

// Tabs Mixin
@mixin tabs {
    @for $i from 1 through $num-of-tabs {
        &:nth-of-type(#{$i}) {
            &:checked {
                ~.slide {
                    left: calc((100% / #{$num-of-tabs}) * #{$i - 1});
                }
            }
        }
    }
}

// Tab Styles
.tab-wrap {
    width: 100%;
    position: relative;
    display: flex;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12); // Add subtle shadow
    // Tab Header
    label.tab-header {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.8);
        background-color: $cyan;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: $tab-header-height;
        transition: color 0.2s ease, background-color 0.2s ease; // Add smooth transition
        width: 100%;
        margin: 0;
        padding: 0 15px; // Add padding for text
        font-weight: 500; // Make text more visible
        &:hover {
            background-color: color.adjust($cyan, $lightness: -5%);
        }
    }
    // Slide Indicator
    .slide {
        background: $yellow;
        width: calc(100% / #{$num-of-tabs});
        height: 4px;
        position: absolute;
        left: 0;
        top: calc(100% - 4px);
        transition: left 0.3s ease-out;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Add subtle shadow
    }
    // Tab Content
    .tab-label-content {
        width: 100%;
        position: relative; // Add this
        .tab-content {
            position: absolute;
            top: $tab-header-height;
            left: 0;
            display: none;
            padding: $tab-content-padding;
            background: rgba(255, 255, 255, 0.9);
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1); // Add border
            border-top: none; // Remove top border
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add shadow
        }
    }
    // Radio Inputs
    input[type="radio"][name="tabs"] {
        position: absolute;
        z-index: -1;
        opacity: 0; // Hide radio buttons
        &:checked {
            +.tab-label-content {
                label.tab-header {
                    color: white;
                    background-color: color.adjust($cyan, $lightness: -10%);
                }
                .tab-content {
                    display: block;
                    animation: fadeIn 0.3s ease-in; // Add fade in animation
                }
            }
        }
        @include tabs;
        &:first-of-type:checked {
            ~.slide {
                left: 0;
            }
        }
    }
}

// Add animation
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

// Add responsive styles
@media (max-width: 768px) {
    .tab-wrap {
        flex-direction: column;
        label.tab-header {
            width: 100%;
        }
        .slide {
            display: none; // Hide slide on mobile
        }
        .tab-label-content {
            .tab-content {
                position: relative;
                top: 0;
            }
        }
    }
}

// Fix Material Design conflicts
:host ::ng-deep {
    .mat-tab-group {
        .tab-wrap {
            // Override Material Design styles if needed
            .tab-header {
                background-color: $cyan !important;
            }
        }
    }
}