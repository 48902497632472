.spinner {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9000;
    .spinner-loader {
        position: absolute;
        padding: 0;
        width: 40px;
        margin: auto;
        z-index: 9000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .circle {
            position: absolute;
            width: 38px;
            height: 38px;
            opacity: 0;
            transform: rotate(225deg);
            animation-iteration-count: infinite;
            animation-name: orbit;
            animation-duration: 4s;
            &:after {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 5px;
                background: #FFFFFF;
            }
            &:nth-child(2) {
                animation-delay: 240ms;
            }
            &:nth-child(3) {
                animation-delay: 480ms;
            }
            &:nth-child(4) {
                animation-delay: 720ms;
            }
            &:nth-child(5) {
                animation-delay: 960ms;
            }
        }
    }
}

@keyframes orbit {
    0% {
        transform: rotate(225deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }
    7% {
        transform: rotate(345deg);
        animation-timing-function: linear;
    }
    30% {
        transform: rotate(455deg);
        animation-timing-function: ease-in-out;
    }
    39% {
        transform: rotate(690deg);
        animation-timing-function: linear;
    }
    70% {
        transform: rotate(815deg);
        opacity: 1;
        animation-timing-function: ease-out;
    }
    75% {
        transform: rotate(945deg);
        animation-timing-function: ease-out;
    }
    76% {
        transform: rotate(945deg);
        opacity: 0;
    }
    100% {
        transform: rotate(945deg);
        opacity: 0;
    }
}