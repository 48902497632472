@use '@angular/material' as mat;
// Your variables and other imports
@use './styles/variables' as *;
@use './styles/bootstrap-table-custom' as *;
@use './styles/_spinner.scss' as *;
@use './styles/_dropdown.scss' as *;
@use './styles/_button.scss' as *;
@use 'sweetalert2/src/sweetalert2.scss' as *;
@font-face {
    font-family: "HNThin";
    src: url("./assets/fonts/hn35t.woff2") format("woff2"), url("./assets/fonts/hn35t.woff") format("woff");
}

@font-face {
    font-family: "HNLight";
    src: url("./assets/fonts/hn45l.woff2") format("woff2"), url("./assets/fonts/hn45l.woff") format("woff");
}

@font-face {
    font-family: "HNRegular";
    src: url("./assets/fonts/hn55r.woff") format("woff"), url("./assets/fonts/hn55r.woff2") format("woff2");
}

@font-face {
    font-family: "HNBold";
    src: url("./assets/fonts/hn75b.woff") format("woff"), url("./assets/fonts/hn75b.woff2") format("woff2");
}

@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto.ttf");
}

body {
    width: 100vw;
    height: 100vh;
    padding: 0px;
    overflow: hidden !important;
    font-family: "HNThin", serif;
}

.sub-panel {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    margin-bottom: 20px;
    hr {
        margin: 5px 0px;
        border-top: solid 1px rgba(0, 0, 0, 0.2)
    }
    h2,
    h3,
    h4,
    h5 {
        margin: 0px;
        border-bottom: 1px solid #999999;
        padding-bottom: 10px;
        margin-bottom: 10px;
        .show-hide-panel {
            float: right;
            display: block !important;
        }
        .show-hide-panel {
            .expand_less {
                display: block;
            }
            .expand_more {
                display: none;
            }
        }
        .show-hide-panel.hidden {
            .expand_less {
                display: none;
            }
            .expand_more {
                display: block;
            }
        }
    }
    .sub-panel {
        border: solid 1px #DDDDDD;
    }
}

input[type="text"],
input[type="number"] {
    border-radius: 0px !important;
    height: 35px !important;
    width: auto !important;
    // min-width: 300px;
}

input[type="text"].fc1 {
    min-width: 300px;
}

.toggle {
    cursor: pointer;
    display: inline-block;
}

.toggle-switch {
    display: inline-block;
    background: #CCCCCC;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    &:before,
    &:after {
        content: "";
    }
    &:before {
        display: block;
        background: linear-gradient(to bottom, #FFFFFF 0%, #EEEEEE 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
        width: 24px;
        height: 24px;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: left 0.25s;
    }
    .toggle:hover &:before {
        background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 100%);
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }
    .toggle-checkbox:checked+& {
        background: #56C080;
        &:before {
            left: 30px;
        }
    }
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">" !important;
}

.breadcrumb {
    border-radius: 0px !important;
    padding: 10px !important;
    margin-bottom: 0px !important;
    background-color: #e9ecef;
    .breadcrumb-item {
        font-size: 14px;
    }
}

.text-right {
    text-align: right !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

body.swal2-shown.swal2-height-auto {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}